import React from 'react'
import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'
import bgPattern from '@/assets/images/services/background-pattern.svg'
import { media } from '@/styles/media'
import { Container } from './Container'
import InnerContainer from './InnerContainer'
import SectionTitle from '../generic/SectionTitle'

const Wrapper = styled.div<{
  bgText: string
  customStyle: SerializedStyles
}>`
  display: flex;
  height: 360px;

  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  width: 60%;
  padding: 52px 96px;

  &:before {
    position: absolute;
    white-space: nowrap;
    top: 25px;
    left: 0;
    content: ${({ bgText }) => `"${bgText}"`};
    color: #88b9db;
    font-family: 'Zen Antique Soft';
    font-weight: 400;
    font-size: 256px;
    font-style: normal;
    line-height: 256px;
    text-transform: uppercase;

    opacity: 0.15;
    mix-blend-mode: hard-light;
  }

  ${media.bigDesktopDown} {
    width: 60%;

    &:before {
      position: absolute;
      white-space: nowrap;
      top: 0;
      content: ${({ bgText }) => `"${bgText}"`};
      color: #88b9db;
      font-size: 120px;
      line-height: 120px;
    }
  }

  ${media.tabletLandscapeDown} {
    align-items: center;
    width: 100%;
    padding: 40px 24px;
    height: auto;

    &:before {
      top: 50%;
      left: -50%;
      transform: translate(35%, -50%);
    }
  }

  ${({ customStyle }) => customStyle}
`

const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-self: stretch;
`

const Title = styled.div`
  ${media.tabletLandscapeDown} {
    margin-top: 16px;
  }
`

export default function StyledSection({
  children,
  sectionTitleText,
  sectionSecondText = '',
  sectionTitleColor,
  bgText = '',
  containerBgColor = '#001B2E',
  wrapperCustomStyle = css``
}) {
  return (
    <Container
      style={{
        background: containerBgColor,
        backgroundImage: `url(${bgPattern})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center right',
        overflowX: 'hidden'
      }}
      mobileStyle={css`
        background-image: none !important;
      `}
    >
      <InnerContainer style={{ flexDirection: 'row', margin: '0 auto' }}>
        <Wrapper bgText={bgText} customStyle={wrapperCustomStyle}>
          <SectionTitle
            text={sectionTitleText}
            secondText={sectionSecondText}
            color={sectionTitleColor}
            isH1
          />
          <Title />

          {children}
        </Wrapper>
        <RightWrapper>&nbsp;</RightWrapper>
      </InnerContainer>
    </Container>
  )
}
