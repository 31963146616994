import styled from '@emotion/styled'
import React from 'react'

const Button = styled.a`
  width: 140px;
  height: 50px;
  flex-shrink: 0;

  display: inline-flex;
  flex-direction: row;

  gap: 7px;

  padding: 13px 13px 10px 13px;

  box-sizing: border-box;
  text-decoration: none;
  border-radius: 4px;
  background: #000;
  box-shadow: 0px 33px 51px 0px rgba(0, 0, 0, 0.08),
    0px 9.949px 15.375px 0px rgba(0, 0, 0, 0.06),
    0px 4.132px 6.386px 0px rgba(0, 0, 0, 0.04),
    0px 1.495px 2.31px 0px rgba(0, 0, 0, 0.03);
`

const TextWrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: start;
`

const FirstText = styled.div`
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`

const SecondText = styled.div`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`

export default function AppStoreButton() {
  return (
    <Button
      href="https://apps.apple.com/us/app/tokyocatch-claw-machine/id1485188384"
      target="_blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="23"
        viewBox="0 0 19 23"
        fill="none"
      >
        <path
          d="M15.3637 11.9807C15.3854 10.2997 16.2881 8.70915 17.7203 7.82869C16.8168 6.53837 15.3035 5.72027 13.7291 5.67101C12.0499 5.49475 10.4219 6.67584 9.56622 6.67584C8.69395 6.67584 7.37645 5.68851 5.95772 5.7177C4.10847 5.77744 2.3845 6.82883 1.4848 8.44557C-0.449201 11.794 0.993388 16.715 2.846 19.4216C3.7729 20.747 4.85618 22.2274 6.27363 22.1749C7.66069 22.1174 8.17873 21.2905 9.85303 21.2905C11.5118 21.2905 11.9978 22.1749 13.444 22.1416C14.9324 22.1174 15.8702 20.8103 16.7645 19.4724C17.4305 18.5281 17.943 17.4843 18.283 16.3799C16.5338 15.6401 15.3658 13.8799 15.3637 11.9807Z"
          fill="white"
        />
        <path
          d="M12.6321 3.89101C13.4436 2.9168 13.8434 1.66462 13.7466 0.400391C12.5067 0.530611 11.3615 1.12317 10.539 2.06C9.73471 2.97533 9.31616 4.20548 9.39528 5.42139C10.6356 5.43416 11.8549 4.85766 12.6321 3.89101Z"
          fill="white"
        />
      </svg>

      <TextWrapper>
        <FirstText>Download on the</FirstText>
        <SecondText>App Store</SecondText>
      </TextWrapper>
    </Button>
  )
}
