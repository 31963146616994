import styled from '@emotion/styled'
import React from 'react'

const Button = styled.a`
  width: 140px;
  height: 50px;
  flex-shrink: 0;

  display: inline-flex;
  flex-direction: row;

  text-decoration: none;

  gap: 7px;

  padding: 13px 13px 10px 13px;

  box-sizing: border-box;

  border-radius: 4px;
  background: #000;
  box-shadow: 0px 33px 51px 0px rgba(0, 0, 0, 0.08),
    0px 9.949px 15.375px 0px rgba(0, 0, 0, 0.06),
    0px 4.132px 6.386px 0px rgba(0, 0, 0, 0.04),
    0px 1.495px 2.31px 0px rgba(0, 0, 0, 0.03);
`

const TextWrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: start;
`

const FirstText = styled.div`
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`

const SecondText = styled.div`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`

export default function GooglePlayButton() {
  return (
    <Button
      href="https://play.google.com/store/apps/details?id=com.movefastllc.tokyocatch&hl=en"
      target="_blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="27"
        viewBox="0 0 24 27"
        fill="none"
      >
        <g filter="url(#filter0_ii_3255_5813)">
          <path
            d="M1.02965 0.797609C0.738651 1.10561 0.56665 1.58361 0.56665 2.20261V24.3186C0.56665 24.9386 0.738651 25.4156 1.02965 25.7236L1.10365 25.7956L13.4926 13.4066V13.2606V13.1146L1.10365 0.72461L1.02965 0.797609Z"
            fill="url(#paint0_linear_3255_5813)"
          />
          <path
            d="M17.6217 17.5374L13.4927 13.4064V13.2604V13.1144L17.6227 8.98438L17.7157 9.03737L22.6087 11.8174C24.0057 12.6114 24.0057 13.9104 22.6087 14.7054L17.7157 17.4854L17.6217 17.5374Z"
            fill="url(#paint1_linear_3255_5813)"
          />
          <g filter="url(#filter1_i_3255_5813)">
            <path
              d="M17.7155 17.4847L13.4915 13.2607L1.02954 25.7237C1.48954 26.2117 2.25054 26.2717 3.10754 25.7857L17.7155 17.4847Z"
              fill="url(#paint2_linear_3255_5813)"
            />
          </g>
          <path
            d="M17.7155 9.03647L3.10754 0.736466C2.25054 0.249466 1.48954 0.310466 1.02954 0.798466L13.4925 13.2615L17.7155 9.03647Z"
            fill="url(#paint3_linear_3255_5813)"
          />
        </g>
        <defs>
          <filter
            id="filter0_ii_3255_5813"
            x="0.56665"
            y="0.400391"
            width="23.0898"
            height="25.7207"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-0.15" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_3255_5813"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.15" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_3255_5813"
              result="effect2_innerShadow_3255_5813"
            />
          </filter>
          <filter
            id="filter1_i_3255_5813"
            x="1.02954"
            y="13.2607"
            width="16.686"
            height="12.8604"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-0.15" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_3255_5813"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3255_5813"
            x1="12.3945"
            y1="1.96861"
            x2="-4.38755"
            y2="18.7506"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00A0FF" />
            <stop offset="0.0066" stopColor="#00A1FF" />
            <stop offset="0.2601" stopColor="#00BEFF" />
            <stop offset="0.5122" stopColor="#00D2FF" />
            <stop offset="0.7604" stopColor="#00DFFF" />
            <stop offset="1" stopColor="#00E3FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3255_5813"
            x1="24.4272"
            y1="13.2604"
            x2="0.231277"
            y2="13.2604"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE000" />
            <stop offset="0.4087" stopColor="#FFBD00" />
            <stop offset="0.7754" stopColor="#FFA500" />
            <stop offset="1" stopColor="#FF9C00" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3255_5813"
            x1="15.4216"
            y1="15.5546"
            x2="-7.33686"
            y2="38.3131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF3A44" />
            <stop offset="1" stopColor="#C31162" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3255_5813"
            x1="-2.10906"
            y1="-6.56373"
            x2="8.05324"
            y2="3.59857"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32A071" />
            <stop offset="0.0685" stopColor="#2DA771" />
            <stop offset="0.4762" stopColor="#15CF74" />
            <stop offset="0.8009" stopColor="#06E775" />
            <stop offset="1" stopColor="#00F076" />
          </linearGradient>
        </defs>
      </svg>

      <TextWrapper>
        <FirstText>Get it On</FirstText>
        <SecondText>Google Play</SecondText>
      </TextWrapper>
    </Button>
  )
}
